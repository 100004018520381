import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-page" role="main" aria-labelledby="about-page-title">
      {/* Hero Section */}
      <section className="about-hero" aria-labelledby="about-hero-title">
        <div className="about-hero-content">
          <h1 id="about-hero-title">Welcome to Gloss Wise Detailing</h1>
          <p>Your trusted partner in premium car detailing and protection services.</p>
        </div>
      </section>

      {/* Mission and Values */}
      <section className="about-mission" aria-labelledby="mission-title">
        <h2 id="mission-title">Our Mission</h2>
        <p>
          At Gloss Wise, we are dedicated to providing top-tier car detailing services that keep your vehicle looking pristine and protected. We believe in using eco-friendly, high-quality products for long-lasting results.
        </p>
        <h2 id="values-title" className="values-heading">Our Values</h2>
        <ul className="values-list" aria-labelledby="values-title">
          <li>Quality First</li>
          <li>Honesty & Transparency</li>
          <li>Commitment to Excellence</li>
          <li>Customer Satisfaction</li>
        </ul>
      </section>

      {/* Our Story */}
      <section className="about-story" aria-labelledby="story-title">
        <h2 id="story-title">Our Story</h2>
        <p>
          Gloss Wise started from a passion for cars and a drive to deliver unparalleled detailing services. Today, we serve countless satisfied customers with our expert knowledge and attention to detail.
        </p>
      </section>

      {/* Why Choose Us */}
      <section className="why-choose-us" aria-labelledby="why-choose-title">
        <h2 id="why-choose-title">Why Choose Gloss Wise?</h2>
        <div className="choose-us-cards">
          <div className="choose-card" role="region" aria-labelledby="expertise-title">
            <h3 id="expertise-title">Expertise You Can Trust</h3>
            <p>Years of experience in detailing and paint protection.</p>
          </div>
          <div className="choose-card" role="region" aria-labelledby="satisfaction-title">
            <h3 id="satisfaction-title">100% Satisfaction Guarantee</h3>
            <p>We guarantee you’ll be satisfied, or we’ll make it right.</p>
          </div>
          <div className="choose-card" role="region" aria-labelledby="eco-friendly-title">
            <h3 id="eco-friendly-title">Eco-Friendly Products</h3>
            <p>We use only the best eco-friendly products for our services.</p>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="about-testimonials" aria-labelledby="testimonials-title">
        <h2 id="testimonials-title">What Our Customers Say</h2>
        <div className="testimonials-container">
          <blockquote>
            <p>"Gloss Wise brought my car back to life with impeccable attention to detail!"</p>
            <cite>— Sarah</cite>
          </blockquote>
          <blockquote>
            <p>"The best car detailing experience I've ever had. Highly recommend!"</p>
            <cite>— Michael</cite>
          </blockquote>
        </div>
      </section>

      {/* Call to Action */}
      <section className="about-cta" aria-labelledby="cta-title">
        <h2 id="cta-title">Ready to Give Your Car the Care It Deserves?</h2>
        <button 
          className="cta-button" 
          onClick={() => window.location.href = '/booking'}
          aria-label="Book your car detailing service now"
        >
          Book Your Detailing Now
        </button>
      </section>
    </div>
  );
};

export default About;