import React from 'react';
import './Footer.css';
import logo from '../assets/images/logo.png';

const Footer = () => {
  return (
    <footer className="footer" aria-label="Gloss Wise Footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={logo} alt="Gloss Wise logo" />
        </div>
        <p>© 2024 Gloss Wise. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
