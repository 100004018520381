import React from 'react';
import './Testimonials.css'; // Custom CSS for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faStar } from '@fortawesome/free-solid-svg-icons';

const testimonialsData = [
  {
    id: 1,
    name: 'John',
    review: 'The service was outstanding! My car looks brand new, and the team was very professional. I highly recommend Gloss Wise.',
    rating: 5,
  },
  {
    id: 2,
    name: 'Emily',
    review: 'I opted for the full detail package, and the results were amazing. Great attention to detail and very friendly staff!',
    rating: 4,
  },
  {
    id: 3,
    name: 'Michael',
    review: 'Incredible job on the ceramic paint protection. My car has never looked better, and I’m very satisfied with the service.',
    rating: 5,
  },
];

const Testimonials = () => {
  return (
    <section className="testimonials-section" aria-labelledby="testimonials-heading">
      <h2 id="testimonials-heading">What Our Clients Are Saying</h2>
      <div className="testimonials-grid" role="list">
        {testimonialsData.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-card" role="listitem" aria-label={`Testimonial from ${testimonial.name}`} tabIndex={0}>
            <FontAwesomeIcon icon={faQuoteLeft} className="quote-icon" aria-hidden="true" />
            <p className="testimonial-review">{testimonial.review}</p>
            <div className="testimonial-rating" aria-label={`Rating: ${testimonial.rating} out of 5 stars`}>
              {[...Array(testimonial.rating)].map((_, i) => (
                <FontAwesomeIcon key={i} icon={faStar} className="star-icon" aria-hidden="true" />
              ))}
            </div>
            <h4 className="testimonial-name">{testimonial.name}</h4>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;