import servicesData from '../data/servicesData';
import './Services.css'; // Assuming you're keeping the same styling

const Services = () => {
  const renderServicesByCategory = (category) => {
    return servicesData
   .filter(service => service.category === category)
   .map(service => (
     <div key={service.id} className="service-card">
       <img src={service.image} alt={`${service.title} - service`} className="service-image" />
       <h3>{service.title}</h3>
       <p>{service.description}</p>
       <p className="service-price">${service.price}</p>
       <div className="service-actions">
         <button className="cta-button" onClick={() => window.location.href = `/booking`}>Book Now</button>
       </div>
     </div>
   ));
  };

  return (
    <section className="services-section">
      <h2>Interior Detailing Packages</h2>
      <div className="services-grid">
        {renderServicesByCategory('Interior')}
      </div>
      <h2>Exterior Detailing Packages</h2>
      <div className="services-grid">
        {renderServicesByCategory('Exterior')}
      </div>
      <h2>Combo Packages</h2>
      <div className="services-grid">
        {renderServicesByCategory('Combo')}
      </div>
    </section>
  );
};

export default Services;
