import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css'; // Ensure proper CSS for styling
import logo from '../assets/images/logo.png';
import logoText from '../assets/images/logoText.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false); // State to track menu open/close

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle menu state
  };

  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Gloss Wise Logo" className="logo" />
        <img src={logoText} alt="Gloss Wise Text" className="logo-text" />
      </div>

      {/* Hamburger icon for mobile */}
      <button
        className="hamburger"
        onClick={toggleMenu}
        aria-label={menuOpen ? 'Close menu' : 'Open menu'}
        aria-expanded={menuOpen}
      >
        <i className={menuOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
      </button>

      <nav className={`navbar ${menuOpen ? 'open' : ''}`} aria-label="Main Navigation">
        <ul>
          <li>
            <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')} onClick={() => setMenuOpen(false)} aria-current="page">Home</NavLink>
          </li>
          <li>
            <NavLink to="/about" className={({ isActive }) => (isActive ? 'active' : '')} onClick={() => setMenuOpen(false)}>About</NavLink>
          </li>
          <li>
            <NavLink to="/booking" className={({ isActive }) => (isActive ? 'active' : '')} onClick={() => setMenuOpen(false)}>Book</NavLink>
          </li>
          <li>
            <NavLink to="/contact" className={({ isActive }) => (isActive ? 'active' : '')} onClick={() => setMenuOpen(false)}>Contact</NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
