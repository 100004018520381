import expressInteriorDetail from '../assets/images/expressInteriorDetail.jpg';
import fullInteriorDetail from '../assets/images/fullInteriorDetail.jpg';
import premiumInteriorDetail from '../assets/images/premiumInteriorDetail.jpg';

import expressExteriorDetail from '../assets/images/expressExteriorDetail.jpg';
import fullExteriorDetail from '../assets/images/fullExteriorDetail.jpg';
import premiumExteriorDetail from '../assets/images/premiumExteriorDetail.jpg';

import expressComboDetail from '../assets/images/expressComboDetail.jpg';
import fullComboDetail from '../assets/images/fullComboDetail.jpg';
import premiumComboDetail from '../assets/images/premiumComboDetail.jpg'

const servicesData = [
  {
    id: 1,
    title: 'Express Interior Detail',
    category: 'Interior',
    description: 'Quick interior clean including vacuum, surface wipe, and window cleaning.',
    price: 110,
    image: expressInteriorDetail
  },
  {
    id: 2,
    title: 'Full Interior Detail',
    category: 'Interior',
    description: 'Thorough interior cleaning with stain removal, deep clean, and steam disinfecting.',
    price: 200,
    image: fullInteriorDetail
  },
  {
    id: 3,
    title: 'Premium Interior Detail',
    category: 'Interior',
    description: 'Top-tier interior service with deodorizing, fabric protection, and full disinfecting.',
    price: 300,
    image: premiumInteriorDetail
  },
  {
    id: 4,
    title: 'Express Exterior Detail',
    category: 'Exterior',
    description: 'Basic exterior wash and shine including hand wash, window cleaning, and tire dressing.',
    price: 125,
    image: expressExteriorDetail
  },
  {
    id: 5,
    title: 'Full Exterior Detail',
    category: 'Exterior',
    description: 'Comprehensive exterior clean with light paint correction and 6 month protective coating.',
    price: 220,
    image: fullExteriorDetail
  },
  {
    id: 6,
    title: 'Premium Exterior Detail',
    category: 'Exterior',
    description: 'Ultimate exterior protection with 5 year ceramic coating for long-lasting shine.',
    price: 500,
    image: premiumExteriorDetail
  },
  {
    id: 7,
    title: 'Express Combo Detail',
    category: 'Combo',
    description: 'A quick interior and exterior clean at a discounted rate.',
    price: 200,
    image: expressComboDetail
  },
  {
    id: 8,
    title: 'Full Combo Detail',
    category: 'Combo',
    description: 'Complete interior and exterior clean with stain removal and paint correction.',
    price: 400,
    image: fullComboDetail
  },
  {
    id: 9,
    title: 'Premium Combo Detail',
    category: 'Combo',
    description: 'The ultimate detailing package combining all premium services for both interior and exterior.',
    price: 750,
    image: premiumComboDetail
  },
];

export default servicesData;
